<template>
  <div class="project-list">
    <slot />
      <label class="field__label">Search</label>
      <div class="field__input project-list__field">
          <input
              v-model="search"
              class="form-control project-list__search"
              type="text"
              placeholder="Start typing a project name to filter the list..."
              @input="filterList()"
          >
          <div class="dropdown-container">
              <label class="turnstile-text">Turnstile Providers</label>
              <select
                  v-model="turnstileProviderFilter"
                  class="form-control project-list__dropdown"
                  @change="filterListByDropdown"
              >
                  <option value="">All</option>
                  <option value="MSite">MSite</option>
                  <option value="Site Sentinel">Site Sentinel</option>
                  <option value="N/A">N/A</option>
              </select>
          </div>
          <div class="lco-dropdown-container">
              <label class="turnstile-text">LCO</label>
              <select
                  v-model="lcoFilter"
                  class="form-control project-list__dropdown"
                  @change="filterListByDropdown"
              >
                  <option value="">All</option>
                  <option v-for="lco in lcos" :key="lco" :value="lco">
                      {{ lco }}
                  </option>
              </select>
          </div>
          <div class="crm-dropdown-container">
              <label class="turnstile-text">CRM Status</label>
              <select
                  v-model="crmFilter"
                  class="form-control project-list__dropdown"
                  @change="filterListByDropdown"
              >
                  <option value="">All</option>
                  <option v-for="status in crmStatuses" :key="status" :value="status">
                    {{ status }}
                  </option>
              </select>
          </div>
      </div>
    <table class="project-list__table">
      <thead>
      <tr>
      <th class="text-center">
          Project ID
      </th>
        <th
          ref="header-name"
          class="project-list__th"
        >
          Project Name
        </th>
        <th class="text-center">
          {{clientConfigOffice}}
        </th>
        <th class="text-center">
          Turnstile Provider
        </th>
        <th class="text-center">
          Turnstile Provider ID
        </th>
        <th class="text-center">
          CRM Status
        </th>
        <th class="text-center">
          Contract No.
        </th>
        <th class="text-center">
          Live
        </th>
        <th class="text-center">
          No. of MSite linked operatives
        </th>
        <th v-if="isDeactivate"
        class="project-list__action text-center">
          Action
        </th>
      </tr>
      </thead>
      <tbody>
        <tr
          v-for="(project, key) in chunckList"
          :key="key"
        >
          <td class="project-list__td">
             {{ project.id }}
          </td>
          <td class="project-list__td">
            {{ project.name }}
          </td>
          <td class="project-list__td">
            {{ project.lco.name }}
          </td>
          <td class="project-list__td">
            {{ getTurnstileProviderName(project.turnstile_provider) }}
          </td>
          <td class="project-list__td">
            {{ project.turnstile_provider ? project.turnstile_reference : 'N/A' }}
          </td>
          <td class="project-list__td">
            {{ project.crm_status ? project.crm_status : "N/A" }}
          </td>
          <td class="project-list__td">
            {{ project.project_code ? project.project_code : "N/A" }}
          </td>
          <td class="text-center">
            <tick-svg
              v-if="project.active == 1"
              tick-color="#A6CB77"
            />
            <cross-svg
              v-else
              cross-color="#9B9B9B"
            />
          </td>
          <td class="project-list__td">
              <a v-if="project.turnstile_provider === 'MSITE'" :href="`/admin/msite-verification/${project.id}/linked-users`">
                  {{ project.linked_users_count }}
              </a>
              <span v-else>
                  {{ "N/A" }}
              </span>
          </td>
          <td v-if="isDeactivate"
              class="project-list__td text-center">
            <button
              v-if="isDeactivate && project.active === 1"
              class="project-list__button danger"
              @click="checkAction(key, 0)"
            >
              Deactivate
            </button>
            <button
              v-if="isDeactivate && project.active === 0"
              class="project-list__button success"
              @click="checkAction(key, 1)"
            >
              Activate
            </button>
          </td>
        </tr>
      </tbody>
    </table>
      <ul class="results__pagination">
          <li
              @click="setPage(activePage - 1)"
              v-if="activePage > 1"
              class="project-list__pagination-item"
          >
              <span>Previous</span>
          </li>
          <li
              v-for="index in generatePagination"
              :key="index"
              :class="{ 'project-list__pagination-item--active': index === activePage }"
              class="project-list__pagination-item"
              @click="setPage(index)"
          >
              <span>{{ index }}</span>
          </li>
          <li
              @click="setPage(activePage + 1)"
              v-if="activePage < Math.ceil(dataList.length / chunk)"
              class="project-list__pagination-item"
          >
              <span>Next</span>
          </li>
      </ul>
    <div
      v-show="projectModal"
      class="project-list__modal pre-lightbox"
    >
      <div class="pre-lightbox__content project-list__lightbox--content">
        <p>
          <strong>
            Are you sure you want to
            <template v-if="selectedProject.state == 0">
              de
            </template>
            activate this project on mi | pre-enrolment</strong>
        </p>
        <p>{{ selectedProject.name }}</p>
        <div class="pre-lightbox__footer">
          <button
            class="button outline"
            @click="projectModal = false"
          >
            Cancel
          </button>
          <button
            class="button success"
            @click="manageProject()"
          >
            Yes,<template v-if="selectedProject.state == 0">
              de
            </template>
            activate this project
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from 'axios';
import TickSvg from '../ui/TickSvg';
import CrossSvg from '../ui/CrossSvg';
import MagnifySvg from '../ui/MagnifySvg';

export default {
  name: 'ProjectList',
  components: {
    TickSvg,
    CrossSvg,
    MagnifySvg,
  },
  props: {
    projects: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    isDeactivate: {
        type: Boolean,
        default: false,
    },
    lcos: {
      type: Object,
      default() {
          return [];
      },
    },
    crmStatuses: {
        type: Array,
        default() {
            return [];
        },
    },
  },
  data() {
    return {
      dataList: this.projects,
      filteredList: this.projects,
      selectedProject: {
          id: null,
          name: null,
          key: null,
          state: null,
      },
      projectModal: false,
      search: '',
      turnstileProviderFilter: '',
      lcoFilter: '',
      crmFilter: '',
      activePage: 1,
      start: 0,
      chunk: 15,
    };
  },
  computed: {
    chunckList() {
        return this.filteredList.slice(this.start, this.start + this.chunk);
    },
    generatePagination() {
      const totalPages = Math.ceil(this.filteredList.length / this.chunk);
      const maxPagesToShow = 3; // Maximum amount of pages to show
      const half = Math.floor(maxPagesToShow / 2); // Calculate how many page links appear before and after the active page

      let start = Math.max(1, this.activePage - half);
      let end = Math.min(totalPages, this.activePage + half);

      // Create start and end values to ensure consistent pagination length
      if (this.activePage <= half) {
          end = Math.min(totalPages, maxPagesToShow);
      } else if (this.activePage + half >= totalPages) {
          start = Math.max(1, totalPages - maxPagesToShow + 1);
      }

      const pagination = [];
      for (let i = start; i <= end; i++) {
          pagination.push(i);
      }

      return pagination;
    },
    clientConfigOffice() {
      return window.clientConfig['client-office'];
    },
  },
  methods: {
    getTurnstileProviderName(provider) {
      switch (provider) {
        case 'MSITE':
          return 'MSite';
        case 'SITE_SENTINEL':
          return 'Site Sentinel';
        default:
          return 'N/A';
      }
    },
    setPage(page) {
      const totalPages = Math.ceil(this.filteredList.length / this.chunk)
      // Stop invalid page numbers appearing
      if (page < 1 || page > totalPages) return;
      this.activePage = page;
      this.start = (page - 1) * totalPages;
    },
    filterList() {
      const searchTerm = this.search.toLowerCase();

      this.filteredList = this.projects.filter(project => {
          const matchesSearch =
              project.name.toLowerCase().includes(searchTerm) ||
              (project.project_code && project.project_code.toLowerCase().includes(searchTerm));

          const matchesTurnstileProvider =
              !this.turnstileProviderFilter ||
              (this.turnstileProviderFilter === 'MSite' && project.turnstile_provider === 'MSITE') ||
              (this.turnstileProviderFilter === 'Site Sentinel' && project.turnstile_provider === 'SITE_SENTINEL') ||
              (this.turnstileProviderFilter === 'N/A' && !project.turnstile_provider);

          const matchesLco =
              !this.lcoFilter ||
              (project.lco && project.lco.name === this.lcoFilter);

          const matchesCrmStatus =
              !this.crmFilter ||
              (project.crm_status && project.crm_status === this.crmFilter);

          return matchesSearch && matchesTurnstileProvider && matchesLco && matchesCrmStatus;
      });

      this.start = 0;
    },
    filterListByDropdown(){
      this.filterList()
    },
    checkAction(key, state) {
      const { id, name } = this.chunckList[key];
      this.selectedProject = {
        id, key, state, name,
      };
      this.projectModal = true;
    },
    manageProject() {
      const project = this.chunckList[this.selectedProject.key];
      project.active = this.selectedProject.state;
      Object.assign(this.chunckList[this.selectedProject.key], project);
      post(
        `/api/v1/group-admin/projects/${
          this.selectedProject.id
        }/set-status`,
        {
          status: project.active,
        },
      ).catch((err) => {
        console.log(err);
      });
      this.projectModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "./../../../styles/variables.scss";

.project-list {
    &__table {
        width: 100%;
        background-color: white;

        th, td {
            border: 1px solid rgba(0, 0, 0, 0.15);
            font-size: 0.875rem;
            text-align: center;
        }

        th {
            padding: 0.8rem;
        }

        td {
            color: #4C4C4C;
        }
    }

    &__th, &__td {
        padding: 0 0.8rem;
    }

    &__action {
        max-width: 10rem;
    }

    &__search, .project-list__search {
        font-size: 0.8rem;
        max-width: 25%;
    }

    &__button, .button {
        border-radius: 1rem;
        margin: 0.5rem 0;
        font-size: 0.8rem;
        color: white;
        width: 100%;
        max-width: 8rem;
        padding: 0.3rem 0.5rem;
        border: 1px solid white;

        &.danger {
            background-color: #c73824;
        }

        &.success {
            background-color: #a6cb77;
        }
    }

    &__lightbox--content {
        text-align: center;
        padding: 2rem;
    }

    &__pagination, .results__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        &-item {
            display: inline-block;
            padding: 1rem;
            color: $dark-green;
            cursor: pointer;

            &--active {
                color: $black;
            }
        }
    }

    .pre-lightbox__footer {
        display: flex;
        align-content: center;
        justify-content: center;

        .button {
            margin-bottom: 0;

            &.outline {
                padding: 0 1.5rem;
                margin-right: 1.5rem;
            }
        }
    }

    &__field {
        display: flex;

        .project-list__dropdown {
            font-size: 0.8rem;
            -webkit-appearance: none;
        }
    }

    .dropdown-container,
    .lco-dropdown-container,
    .crm-dropdown-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: -1.5rem 0 1rem 0.5rem;
        width: 25%;

        &::after {
            content: "\f078";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            margin-left: 12rem;

            @media (min-width: 1440px) {
                margin-left: 20rem;
            }

            margin-top: -2.25rem;
            margin-bottom: 2rem;
        }
    }


    .turnstile-text {
        text-transform: uppercase;
        font-size: 0.8rem;
        line-height: 1rem;
        font-weight: 700;
        color: #b8b8b8;
        letter-spacing: 0.05rem;
    }
}
</style>
